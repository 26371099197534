import Backbone from 'backbone';
import viewTemplate from 'templates/content/file_view.handlebars';
import editTemplate from 'templates/content/file_edit.handlebars';

import ContentView from '../content';
import { event } from 'jquery';

const FileUploaderView = Backbone.View.extend({
  /* events: {
    "change input": "onChange"
  }, */
  initialize(options) {
    this.fileUploadHandler = options.fileUploadHandler;
    this.multiFileUpload = options.multiFileUpload;

    this.onChangeCallback = options.onChange;
    this.onCancelCallback = options.onCancel;
    this.onCompleteCallback = options.onComplete;
    this.onMultiFileUploadCallback = options.onMultiFileUpload;

    this.cancelUpload = null;
    this.$text = null;
    this.originalText = null;
    this.oldElement = null;
  },
  render(element) {
    if (this.oldElement) this.oldElement.off('change', this.render);
    this.$text = element.next('label').find('span');
    if (!this.originalText) this.originalText = this.$text.text();
    element.change((e) => { this.onChange(e); });
    this.oldElement = element;
  },
  onChooseFile(file) {
    this.onChangeCallback(file.name);
    this.$text.text('Uploading...');

    this.cancelUpload = this.fileUploadHandler.doUpload(file, (fileUUID) => {
      this.$text.text(file.name);
      this.onCompleteCallback(fileUUID, file.name);
    }, (progress) => {
      this.$text.text(`Uploading: ${(progress * 100).toFixed(2)}%...`);
    });
  },
  onChange(e) {
    // Cancel in-progress upload since the selected file has changed
    if (this.cancelUpload !== null) {
      this.cancelUpload();
      this.onCancelCallback();
    }

    // Change dialog text
    if (!e.currentTarget.value) {
      this.$text.text(this.originalText);
      return;
    }

    // TODO: handle the following
    // > (greater than)
    // : (colon)
    // " (double quote)
    // / (forward slash)
    // \ (backslash)
    // | (vertical bar or pipe)
    // ? (question mark)
    // * (asterisk)

    // Upload
    const { files } = e.currentTarget;
    if (files) {
      const file = files[0];
      this.onChooseFile(file);
      if (files.length > 1) {
        const otherFiles = Array.from(files);
        otherFiles.shift();
        this.onMultiFileUploadCallback(otherFiles);
      }
    }
  },
});

// Backbone view
const FileContentView = ContentView.extend({
  events: {
    'click .deletebutton': 'onClickFileRemove',
  },
  initialize(options) {
    ContentView.prototype.initialize.call(this, {
      app: options.app, content: options.content, parent: options.parent, viewTemplate, editTemplate, previewTemplate: null, pageCallbacks: options.pageCallbacks,
    });
    this.inProgress = false;
    this.fileUUID = null;
    this.fileName = null;
    this.additionalData = null;
    this.multiFileUpload = false;
    if (this.parent.typename === 'ListContentView') {
      this.multiFileUpload = true;
    }
    this.uploadHandler = this.app.project.uploadHandler;
    this.fileUploader = new FileUploaderView({
      fileUploadHandler: this.uploadHandler,
      multiFileUpload: this.multiFileUpload,
      onChange: (fileName) => { this.onFileChange(fileName); },
      onComplete: (fileUUID, fileName) => { this.onComplete(fileUUID, fileName); },
      onCancel: () => { this.onCancel(); },
      onMultiFileUpload: (files) => { this.parent.onMultiFileUpload(files); },
    });
  },
  getDownloadURL() {
    return this.uploadHandler.getDownloadURL(this.content.value);
  },
  renderEdit() {
    ContentView.prototype.renderEdit.call(this);
    this.$input = this.$el.find('input');
    if (this.multiFileUpload) this.$input.attr('multiple', '');
    if (this.fileUploader) this.fileUploader.remove();
    this.fileUploader.render(this.$input);
    if (this.mode === 'edit' && !this.multiFileUpload) {
      this.$el.append('<button class="deletebutton outline-button" style="position: absolute; top: 0px; right: 16px;"><i class="fa fa-fw fa-trash"></i> Delete</button>');
    }
  },
  renderView() {
    ContentView.prototype.renderView.call(this);
    if (this.content.contentType.name === 'file-image'
      || (
        this.content.contentType.name === 'file'
        && this.content.value
        && (
          this.content.value.toLowerCase().includes('png')
          || this.content.value.toLowerCase().includes('bmp')
          || this.content.value.toLowerCase().includes('jpg')
          || this.content.value.toLowerCase().includes('jpeg')
          || this.content.value.toLowerCase().includes('gif')
        ) // hack
      )
    ) {
      this.$el.find('a').prepend(`<img width="100px" src="${this.getDownloadURL()}" style="padding-bottom: 1rem;"><br/>`);
    }
    this.$el.find('a').attr('href', this.getDownloadURL());
  },
  onFileChange(fileName) {
    this.inProgress = true;
    this.fileName = fileName;
  },
  onComplete(fileUUID, fileName) {
    this.inProgress = false;
    this.fileUUID = fileUUID;
    const destinationPath = this.content.getDataStoreFilePath(`${fileUUID}_${this.fileName}`);
    this.change(destinationPath);
    this.additionalData = { fileName, fileUUID };
  },
  onCancel() {
    this.inProgress = false;
    this.fileName = null;
    this.fileUUID = null;
    this.changed = false;
    this.newValue = undefined;
    this.additionalData = null;
  },
  onClickFileRemove(e) {
    e.preventDefault();
    this.remove();
    this.$input = this.$el.find('input');
    this.$text = this.$input.next('label').find('span');
    this.$text.text('Choose a file');
  },
  validate() {
    if (this.inProgress) return false;
    return ContentView.prototype.validate.call(this);
  },
  save() {
    if (this.changed && ((this.fileName && this.fileUUID) || this.removed) && !this.inProgress) {
      /* $.ajax({
          type: "POST",
          url: "/moveUploadedFile",
          data: JSON.stringify({ uploadFile: that.awsObjectKey, newPath: that.newValue, oldPath: this.content.value }),
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          success: function(data){
            console.info("move complete:", that.awsObjectKey, that.newValue);
            onComplete();
          },
          error: function(data){
            console.error(data);
            onCancel();
          }
      }); */

      this.content.save(this.newValue);
    } else {
      throw new Error('Could not save.');
    }
    return Promise.resolve();
  },
});
ContentView.extend(FileContentView);

export default {
  name: ['file', 'file-image', 'file-video', 'file-document'],
  view: FileContentView,
};
